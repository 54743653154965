// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-company-645193656683-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://hp4pbzk1k2.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_BF5NOQWhr",
    APP_CLIENT_ID: "1pvts8im7uv7i45krlr9gbptdo",
    IDENTITY_POOL_ID: "eu-central-1:a07e4ed2-2af8-4189-906d-90f50cc02ea9",
  },
  locales: {
    URL: "https://locales.platform.beta.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform.beta.beteiligungsboerse.net",
  },
  integrations: {
    CALENDLY_TOKEN: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc3NjYxNDcwLCJqdGkiOiI2ZTlmYzY4Yi1hZWJhLTRiMzQtYjNlMC1hMGQ0NzZmNWNjNzAiLCJ1c2VyX3V1aWQiOiJkNjljMTA4ZS0wNWIxLTRmYTktYWM3ZS1iMDFjMzZhZjUxYzcifQ.YqPhuktnuWOINLsxaMvx7ykTlNjzx5dTVas2rCIw7bZXXh3jimShVTfOOIuvbMujkPXnScfz8ErrIpisrrLyLQ",
    CALENDLY_URL: "https://calendly.com/beteiligungsboerse-dev/30min?hide_gdpr_banner=1",
  },
  registration: {
    URL: "https://registration.platform.beta.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.company.beta.beteiligungsboerse.net",
    TO_REFERRER: "https://www.beteiligungsboerse.eu/register-success/",
  },
  product: {
    PRODUCT_KEY: "company",
  },
};

export default config;
